.footer {
    margin-top: 40px;
    width: 100%;
    background-color: #f8f8f8;
    padding: 40px 0px;
    text-align: center;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  .footer-section {
    flex: 1;
    margin: 20px;
    min-width: 250px;
  }
  
  .footer-section h3 {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
  }
  
  .footer-section p {
    font-size: 1em;
    color: #666;
    margin: 5px 0;
  }
  
  .footer-socials {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
  }
  
  .footer-socials li {
    margin: 0 10px;
  }
  
  .footer-socials a {
    text-decoration: none;
    color: #333;
    font-size: 1em;
    transition: color 0.3s;
  }
  
  .footer-socials a:hover {
    color: #FF6F00;
  }
  
  .footer-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .footer-form input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px 0 0 5px;
    font-size: 1em;
    flex: 1;
  }
  
  .footer-form button {
    padding: 10px 20px;
    border: none;
    background-color: #FF6F00;
    color: #fff;
    font-size: 1em;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
    transition: background-color 0.3s;
    flex: 0;
  }
  
  .footer-form button:hover {
    background-color: #e65c00;
  }
  
  .footer-bottom {
    margin-top: 20px;
    font-size: 1em;
    color: #666;
    width: 100%;
  }
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-section {
      margin: 10px 0;
      text-align: center;
    }
  
    .footer-form {
      flex-direction: column;
      width: 100%;
    }
  
    .footer-form input, .footer-form button {
      width: 100%;
      margin: 5px 0;
      border-radius: 5px;
    }
  
    .footer-form input {
      margin-bottom: 10px;
    }
  }
  
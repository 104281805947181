/* Reset some default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Set a global font-family and base font size */
body {
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    background-color: #f7f7f7;
}

/* Ensure the full height is taken by sections */
section {
    min-height: 100vh;
    padding: 60px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Heading styles */
h1, h2, h3, h4, h5, h6 {
    margin-bottom: 20px;
    color: #222;
    font-weight: 700;
}

/* Paragraph styles */
p {
    margin-bottom: 20px;
    color: #555;
}

/* Button styles */
button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}

/* Link styles */
a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease;
}

a:hover {
    color: #0056b3;
}

/* Global container for content */
.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

/* Add a smooth scroll behavior */
html {
    scroll-behavior: smooth;
}

/* Responsive styles */
@media (max-width: 768px) {
    body {
        font-size: 14px;
    }

    h1, h2, h3 {
        font-size: 1.5em;
    }

    button {
        padding: 8px 16px;
    }
}

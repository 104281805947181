/* Existing styles */

.hero-section {
  padding: 15px;
  text-align: center;
  background: #f0f8ff;
  color: #333;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.left-content,
.right-content {
  margin: 10px;
}

.left-content {
  flex: 1;
  display: flex;
  justify-content: center;
}

.right-content {
  flex: 1;
  display: flex;
  justify-content: center;
  text-align: left;
}

.main-heading {
  font-size: 3em;
  font-weight: bold;
  margin: 0px 0;
  color: #FF6F00;
}

.sub-heading {
  font-size: 1.7em;
  color: #555;
  margin-bottom: 10px;
}

.description {
  font-size: 1.4em;
  color: #333;
  margin-bottom: 10px;
  line-height: 1.4;
}

.price-tag {
  font-size: 1.4em;
  color: #e74c3c;
  margin: 0px;
}

.original-price {
  text-decoration: line-through;
  margin-right: 0px;
}

.discounted-price {
  font-weight: bold;
  color: #FF6F00;
  font-size: 1.5em;
}

.limited-time {
  font-size: 1.5em;
  color: #e74c3c;
  margin: 10px;
}

.buy-now {
  cursor: pointer;
  display: inline-block;
  padding: 15px 30px;
  font-size: 1.5em;
  color: #fff;
  background: #FF6F00;
  text-decoration: none;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.buy-now-small {
  cursor: pointer;
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.5em;
  color: #fff;
  background: #FF6F00;
  text-decoration: none;
  border-radius: 15px;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}



.buy-now:hover {
  transform: scale(1.05);
}

/* .sticky-buy-now {
  width: 100%;
  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 30px;
  font-size: 1.5em;
  background: #FF4500;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer;
} */

.sticky-buy-now {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 30px;
  font-size: 1.5em;
  background: #FF4500;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: auto;
  max-width: 90%;
  text-align: center;
  white-space: nowrap;

}


.tick-list ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
  margin: 0;
}

.tick-list li {
  font-size: 1.3em;
  margin-bottom: 10px;
  padding-top: 8px;
  padding-left: 30px;
  position: relative;
}

.tick-list li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #FF6F00;
  font-size: 1.2em;
}

.book-cover {
  width: 100%;
  max-width: 300px;
  height: auto;
}

.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 20px;
}

.feature {
  text-align: center;
  max-width: 300px;
  flex: 1;
  margin: 10px;
}

.feature img {
  width: 100%;
  height: auto;
  padding-top: 10px;
}

.benefits {
  margin: 20px 0;
  text-align: center;
}

.benefits h2 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.benefits ul {
  list-style-type: none;
  padding: 0;
  display: inline-block;
  text-align: left;
}

.benefits li {
  font-size: 1.3em;
  margin-bottom: 10px;
  padding-left: 40px;
  position: relative;
}

.benefits li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #FF6F00;
  font-size: 1.5em;
}

.testimonials {
  margin: 20px 0;
}

.testimonials h2 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.testimonial {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 10px;
}

.author {
  margin: 20px 0;
}

.author h2 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.author p {
  font-size: 1.5em;
  color: #555;
}

.guarantee {
  margin: 20px 0;
}

.guarantee h2 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.guarantee p {
  font-size: 1.5em;
  color: #555;
}

.offer-ends {
  font-size: 1.5em;
  color: #e74c3c;
  margin: 0px;
  margin-top: 10px;
}

@media (max-width: 400px) {
  .sticky-buy-now {
   /* white-space: inherit; */
   font-size: .8em;
  }
}


@media (max-width: 700px) {
  .main-heading {
    font-size: 2.1em;
  }

  .sub-heading {
    font-size: 1.3em;
  }

  .description {
    font-size: 1.1em;
  }

  .tick-list li {
    font-size: 1em;
  }

  .price-tag {
    font-size: 1.2em;
  }

  .limited-time {
    font-size: 1.3em;
  }

  .buy-now {
    font-size: 1.3em;
  }

  .sticky-buy-now {
    font-size: 1.3em;
  }

  .features {
    display: inline-block;
    text-align: center;
    margin-bottom: 20px;
  }

  .author h2,
  .guarantee h2,
  .testimonials h2,
  .benefits h2 {
    font-size: 2em;
  }

  .testimonial {
    font-size: 1.1em;
  }


  .benefits li {
    font-size: 1.1em;
  }

  .content-wrapper {
    flex-direction: column;
  }

  .left-content,
  .right-content {
    max-width: 100%;
    text-align: center;
  }
}
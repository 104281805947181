.header {
    background-color: #f8f8f8;
    padding: 10px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .header-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  
  .header-logo {
    width: 70px;
  }
  
  .header-nav {
    display: flex;
  }
  
  .header-menu {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
  }
  
  .header-menu-item {
    margin-left: 20px;
  }
  
  .header-menu-item a {
    text-decoration: none;
    color: #333;
    font-size: 1em;
    transition: color 0.3s;
  }
  
  .header-menu-item a:hover {
    color: #FF6F00;
  }
  
  .header-hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .header-hamburger span {
    background: #333;
    border-radius: 2px;
    display: block;
    height: 2px;
    margin: 2px 0;
    transition: 0.4s;
    width: 25px;
  }
  
  @media (max-width: 768px) {
    .header-nav {
      display: none;
    }
  
    .header-hamburger {
      display: flex;
    }
  
    .header-nav.open {
      display: flex;
    }
  
    .header-menu {
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      background: #f8f8f8;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  
    .header-menu-item {
      margin: 10px 0;
      text-align: center;
    }
  }
  